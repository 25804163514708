import request from '@/utils/request';
export var defaultVRLData = {
  id: 0,
  enabled: true,
  cityId: undefined,
  name: '',
  city: {
    id: undefined
  }
};
export var getVRLs = function getVRLs(params) {
  return request({
    url: '/vrl-delivery-locations',
    method: 'get',
    params: params
  });
};
export var getVRLById = function getVRLById(id) {
  return request({
    url: "/vrl-delivery-locations/".concat(id),
    method: 'get'
  });
};
export var updateVRL = function updateVRL(id, data) {
  return request({
    url: "/vrl-delivery-locations/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteVRL = function deleteVRL(id) {
  return request({
    url: "/vrl-delivery-locations/".concat(id),
    method: 'delete'
  });
};
export var createVRL = function createVRL(data) {
  return request({
    url: '/vrl-delivery-locations/',
    method: 'post',
    data: data
  });
};